/** @jsx jsx */
import * as Sentry from '@sentry/browser'
import { Form, Formik, FormikHelpers } from 'formik'
import firebase from 'gatsby-plugin-firebase'
import React, { useState } from 'react'
import { Flex, jsx } from 'theme-ui'
import * as Yup from 'yup'
import { localCache } from '../utils/browserStorage'
import {
  Box,
  Button,
  Collapse,
  FormGroupInput,
  FormGroupLabel,
  LoadingPlaceholder,
  Row,
  Col,
  Txt,
} from './'
import { Card, lastCardContent } from './Card'
import { ConnectedFormGroup, ConnectedFormGroupDivider } from './ConnectedFormField'
import { FormErrorMessage, GeneralFormErrorMessage } from './FormErrorMessage'
import { captureExceptionWithLabel } from '../utils/sentry'

interface FormValues {
  email: string
}

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Not a valid email address').required('email is required.'),
})

export const UnauthenticatedUserForm: React.SFC<{ backgroundColor: 'light' | 'dark' }> = ({
  children,
  backgroundColor,
}) => {
  const [showEmailExplainer, setShowEmailExplainer] = useState(false)
  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setSubmitting(true)
    try {
      await firebase
        .auth()
        .sendSignInLinkToEmail(values.email, { url: window.location.href, handleCodeInApp: true })
      localCache.setItem('authenticatedUserEmail', values.email)
      actions.setSubmitting(false)
      actions.setStatus('SUCCESS')
    } catch (error) {
      captureExceptionWithLabel(error, 'Submit UnauthenticatedUserForm')
      actions.setFieldError(
        'generalError',
        "Whoops! Looks like we have some issues. We're working on a fix",
      )
      actions.setSubmitting(false)
    }
  }
  return (
    <Formik<FormValues>
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formProps) => {
        return (
          <Form noValidate>
            <Collapse isOpened={formProps.status !== 'SUCCESS'}>
              <Row sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={12} md={6} lg={6}>
                  <ConnectedFormGroup name={'email'}>
                    {({ field, hasErrors }) => (
                      <React.Fragment>
                        <FormGroupLabel htmlFor={field.name}>Enter your email</FormGroupLabel>
                        <ConnectedFormGroupDivider />
                        <FormGroupInput
                          type="email"
                          hasErrors={hasErrors}
                          id={field.name}
                          sx={lastCardContent}
                          {...field}
                        />
                      </React.Fragment>
                    )}
                  </ConnectedFormGroup>
                  <FormErrorMessage
                    sx={{ mt: 2, ...(backgroundColor === 'dark' ? { color: 'white' } : {}) }}
                    name="email"
                  />
                  <GeneralFormErrorMessage
                    sx={{ mt: 2, ...(backgroundColor === 'dark' ? { color: 'white' } : {}) }}
                  />
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={{ size: 3 }}
                  sx={{ textAlign: 'center', mt: [5, null, null, 0] }}
                >
                  <Button
                    block
                    sx={{ px: 2 }}
                    size="lg"
                    type="submit"
                    variant="lightGreen"
                    disabled={formProps.isSubmitting}
                  >
                    <LoadingPlaceholder loadingVariant="dark" loading={formProps.isSubmitting}>
                      Start Playing →
                    </LoadingPlaceholder>
                  </Button>
                </Col>
              </Row>
            </Collapse>
            <Collapse isOpened={formProps.status === 'SUCCESS'}>
              <Card sx={{ p: 4 }}>
                Awesome! We've sent you an email that includes a sign-in link. Please open that
                email and click the link to start playing!
              </Card>
            </Collapse>
            <Collapse isOpened={!showEmailExplainer}>
              <Txt sx={{ textAlign: 'center', fontSize: 1 }}>
                <Button
                  onClick={() => setShowEmailExplainer(true)}
                  variant="link"
                  sx={{ mt: 4, ...(backgroundColor === 'dark' ? { color: 'white' } : {}) }}
                >
                  Why do I need to login?
                </Button>
              </Txt>
            </Collapse>
            <Collapse isOpened={showEmailExplainer}>
              <Txt
                sx={{
                  mt: 4,
                  maxWidth: 600,
                  mx: 'auto',
                  ...(backgroundColor === 'dark' ? { color: 'white' } : {}),
                }}
              >
                <Txt>
                  OurPokerGame helps players keep track of their previous games and see how they're
                  performing against their friends. We need to create a login email to do this.
                </Txt>
                <Txt sx={{ mt: 4 }}>
                  We will never, ever sell any of your information to a third-party and we promise
                  not to send you spam :)
                </Txt>
              </Txt>
            </Collapse>
          </Form>
        )
      }}
    </Formik>
  )
}
