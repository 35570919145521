/** @jsx jsx */
import * as Sentry from '@sentry/browser'
import firebase from 'gatsby-plugin-firebase'
import { appConfig } from '../appConfig'
import { localCache } from '../utils/browserStorage'
import { useEffect } from 'react'
import * as queryString from 'query-string'
import { navigate } from 'gatsby'
import { captureExceptionWithLabel } from '../utils/sentry'
import { sleep } from '../utils/sleep'

export interface IAuthenticatedUserCallbackProps {
  onCompleteAuth?: (cred?: firebase.auth.UserCredential) => void
  onStartAuth?: () => void
}

const authenticateIfComingFromSignInLink = async ({
  onCompleteAuth,
  onStartAuth,
}: IAuthenticatedUserCallbackProps) => {
  try {
    if (!appConfig.IS_BROWSER || !firebase.auth().isSignInWithEmailLink(window.location.href)) {
      if (onCompleteAuth) {
        onCompleteAuth()
      }
      return
    }
    if (onStartAuth) {
      onStartAuth()
    }
    let email = localCache.getItem<string>('authenticatedUserEmail')
    if (!email) {
      captureExceptionWithLabel(
        new Error(
          "firebase.auth().isSignInWithEmailLink returned true but there was no authenticatedUserEmail in the user's localStorage",
        ),
        'authenticateIfComingFromSignInLink',
      )
      email = window.prompt(
        "Looks like you're opening this link in a new browser. Please provide your email for confirmation:",
      )
    }
    const user = await firebase.auth().signInWithEmailLink(email!, window.location.href)
    const { apiKey, ...params } = queryString.parse(location.search)
    navigate(`${window.location.pathname}?${queryString.stringify(params)}`, { replace: true })
    if (onCompleteAuth) {
      onCompleteAuth(user)
    }
  } catch (e) {
    captureExceptionWithLabel(e, 'authenticateIfComingFromSignInLink')
    if (onCompleteAuth) {
      onCompleteAuth()
    }
  }
}

export const useAuthenticateUserCallback = (props: IAuthenticatedUserCallbackProps = {}) => {
  useEffect(() => {
    authenticateIfComingFromSignInLink(props)
  }, [])
}
