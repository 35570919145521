/** @jsx jsx */
import * as Sentry from '@sentry/browser'
import { Form, Formik, FormikHelpers } from 'formik'
import { navigate } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useOrderedNodes } from 'react-register-nodes'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import { jsx } from 'theme-ui'
import * as Yup from 'yup'
import { appConfig } from '../../appConfig'
import {
  Box,
  Button,
  CheckboxAndLabel,
  Col,
  Container,
  FormGroupInput,
  FormGroupLabel,
  Heading,
  LoadingPlaceholder,
  Row,
  SEO,
  Txt,
} from '../../components'
import { lastCardContent } from '../../components/Card'
import { ConnectedFormGroup, ConnectedFormGroupDivider } from '../../components/ConnectedFormField'
import { formatNumberAmount } from '../../components/FormattedNumber'
import { FormErrorMessage, GeneralFormErrorMessage } from '../../components/FormErrorMessage'
import { FormGroupSelect } from '../../components/FormGroup'
import { Modal } from '../../components/Modal'
import { UnauthenticatedUserForm } from '../../components/UnauthenticatedUserForm'
import { useAuthenticateUserCallback } from '../../hooks/useAuthenticateUserCallback'
import { useHasMounted } from '../../hooks/useHasMounted'
import { API, Model } from '../../sharedBetweenServerAndClient__DO_NOT_EDIT/types'
import { getFunction } from '../../utils/api'
import { localCache } from '../../utils/browserStorage'
import { listOfThings } from '../../utils/formatting'
import { globalDebugger } from '../../utils/globalDebugger'
import { captureExceptionWithLabel } from '../../utils/sentry'
import { urls } from '../../utils/urls'
import { theme } from '../../gatsby-plugin-theme-ui/theme'
import { VALID_BLIND_RAISES } from '../../constants'

interface FormValues {
  shouldBlindsIncrease: boolean
  userInvites: Array<{
    userEmail: string
  }>
  rules: {
    initialChipsAmount: string
    numberOfHandsBetweenBlindIncreases: string | null
    startingBlindAmount: string
    gameType: Model.GameType
  }
}

export const validationSchema = Yup.object().shape({
  userInvites: Yup.array(
    Yup.object().shape({
      userEmail: Yup.string().email('Not a valid email address').required('email is required.'),
    }),
  ),
  rules: Yup.object().shape({
    initialChipsAmount: Yup.number()
      .integer('Initial chips must be between 100 and 100,000')
      .min(100, 'Initial chips must be between 100 and 100,000')
      .max(100000, 'Initial chips must be between 100 and 100,000'),
    numberOfHandsBetweenBlindIncreases: Yup.number()
      .nullable()
      .integer("If you'd like to raise blinds please select an integer between 1 - 100")
      .min(1, "If you'd like to raise blinds please select an integer between 1 - 100")
      .max(100, "If you'd like to raise blinds please select an integer between 1 - 100"),
    startingBlindAmount: Yup.number().oneOf(
      VALID_BLIND_RAISES,
      `The starting blind amount must be ${listOfThings(VALID_BLIND_RAISES, 'or')}`,
    ),
    gameType: Yup.string().test(
      'isGameType',
      'Whoops, looks like we have gameType issues',
      (value) => _.includes(['TEXAS_HOLDEM_LIMIT', 'TEXAS_HOLDEM_NO_LIMIT'], value),
    ),
  }),
})

const getInitialValues = (): FormValues => ({
  shouldBlindsIncrease: true,
  userInvites: [],
  rules: {
    initialChipsAmount: '1000',
    numberOfHandsBetweenBlindIncreases: '10',
    startingBlindAmount: '20',
    gameType: 'TEXAS_HOLDEM_NO_LIMIT',
  },
})

const getDemoGameValues = (): FormValues => ({
  ...getInitialValues(),
  userInvites: [
    { userEmail: 'meghan@gmail.com' },
    { userEmail: 'dylan@gmail.com' },
    { userEmail: 'greenie@gmail.com' },
    { userEmail: 'woody@gmail.com' },
    { userEmail: 'bolson@gmail.com' },
    { userEmail: 'zook@gmail.com' },
    { userEmail: 'ginny@gmail.com' },
    { userEmail: 'wrigley@gmail.com' },
    { userEmail: 'wilma@gmail.com' },
  ],
})

const formatPayload = ({
  shouldBlindsIncrease,
  userInvites,
  rules,
}: FormValues): API.CreateGamePayload => {
  return {
    userInvites: userInvites.map(({ userEmail }) => ({
      userEmail,
    })),
    initialRules: {
      initialChipsAmount: parseInt(rules.initialChipsAmount, 10),
      numberOfHandsBetweenBlindIncreases:
        shouldBlindsIncrease && rules.numberOfHandsBetweenBlindIncreases
          ? parseInt(rules.numberOfHandsBetweenBlindIncreases, 10)
          : null,
      startingBlindAmount: parseInt(rules.startingBlindAmount, 10),
      gameType: 'TEXAS_HOLDEM_NO_LIMIT',
    },
  }
}

const FormContent: React.SFC<{}> = ({}) => {
  const [isGeneratingNewGame, setIsGeneratingNewGame] = useState(false)
  useAuthenticateUserCallback({
    onStartAuth: () => {
      setIsGeneratingNewGame(true)
    },
    onCompleteAuth: async (authUser) => {
      if (!authUser) {
        setIsGeneratingNewGame(false)
        return
      }
      const values = localCache.getItem<FormValues>('pendingCreateGameFormValues')
      if (!values) {
        Sentry.captureException(
          new Error('No form values found when reloading the create game form'),
        )
        setIsGeneratingNewGame(false)
        return
      }
      try {
        const response = await getFunction('createGame')(formatPayload(values))
        localCache.removeItem('pendingCreateGameFormValues')
        navigate(urls.gameDetails(response.data.id))
      } catch (e) {
        captureExceptionWithLabel(e, 'useAuthenticateUserCallback FormContent onComplete')
        setIsGeneratingNewGame(false)
      }
    },
  })
  const [showAuthModal, setShowAuthModal] = useState(false)
  const showQuickSearchShortcut = useHasMounted({
    skipRender: appConfig.ENVIRONMENT === 'production' || !globalDebugger.hasTestingEnvironmentUX,
  })
  const initialValues = useMemo(() => getInitialValues(), [])
  const [shouldCheckForScroll, setShouldCheckForScroll] = React.useState(false)
  const ordered = useOrderedNodes()

  React.useEffect(() => {
    if (shouldCheckForScroll && ordered.length > 0) {
      smoothScrollIntoView(ordered[0], {
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'start',
      }).then(() => {
        const input = ordered[0].querySelector('input')
        if (input) {
          input.focus()
        }
        setShouldCheckForScroll(false)
      })
    }
  }, [shouldCheckForScroll, ordered])
  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    setShouldCheckForScroll(true)
    actions.setSubmitting(true)
    try {
      const authUser = firebase.auth().currentUser
      if (authUser) {
        const response = await getFunction('createGame')(formatPayload(values))
        navigate(urls.gameDetails(response.data.id))
      } else {
        setShowAuthModal(true)
        localCache.setItem('pendingCreateGameFormValues', values)
      }
    } catch (error) {
      captureExceptionWithLabel(error, 'CreateGameForm submit')
      actions.setFieldError(
        'generalError',
        "Whoops! Looks like we have some issues. We're working on a fix",
      )
      actions.setSubmitting(false)
    }
  }
  return (
    <React.Fragment>
      <Modal
        sx={{ maxWidth: 700 }}
        label="Authentication Modal"
        isOpen={showAuthModal}
        header={
          <Heading as="h3" sx={{ pb: 3 }}>
            Last step! Sign in to set up your game.
          </Heading>
        }
      >
        <UnauthenticatedUserForm backgroundColor="light" />
      </Modal>
      <Box
        sx={{
          bg: 'darkBlue',
          color: 'white',
          clipPath: 'polygon(0 0, 5760px 0, 5760px calc(100% - 222px), 0 100%)',
          pt: 3,
          pb: [5, null, null, null, 6],
        }}
      >
        <Box sx={{ maxWidth: 720, mx: 'auto', px: 4, textAlign: 'center' }}>
          <Heading styledAs="h1" sx={{ mt: 4, mb: 3 }}>
            Create New Poker Game
          </Heading>
          <Heading as="h2" styledAs="h3">
            Once you confirm the rules for your poker game, you'll get a unique link to send to your
            friends so they can join.
          </Heading>
        </Box>
      </Box>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => {
          return (
            <Form sx={{ maxWidth: 450, mx: 'auto', px: 4 }}>
              <Box sx={{ position: 'relative' }}>
                <Txt
                  sx={
                    isGeneratingNewGame
                      ? {
                          position: 'absolute',
                          top: 50,
                          width: '100%',
                          left: 0,
                          textAlign: 'center',
                        }
                      : { display: 'none' }
                  }
                >
                  Starting your game...
                </Txt>
                <Box sx={isGeneratingNewGame ? { filter: 'blur(2px)', opacity: 0.15 } : {}}>
                  <Row>
                    <Col xs={12} sx={{ mt: [4, 4, 5] }}>
                      <CheckboxAndLabel
                        name={'shouldBlindsIncrease'}
                        onChange={(e) => {
                          const { checked } = e.target
                          formProps.handleChange(e)
                          if (!checked) {
                            formProps.setFieldValue('rules.numberOfHandsBetweenBlindIncreases', '')
                          }
                        }}
                        checked={formProps.values.shouldBlindsIncrease}
                      >
                        Blinds should increase
                      </CheckboxAndLabel>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs={12}
                      sx={{ opacity: formProps.values.shouldBlindsIncrease ? 1 : 0.4, mt: 4 }}
                    >
                      <ConnectedFormGroup name={'rules.numberOfHandsBetweenBlindIncreases'}>
                        {({ field, hasErrors }) => (
                          <>
                            <FormGroupLabel htmlFor={field.name}>
                              # Hands Between Blind Increases
                            </FormGroupLabel>
                            <ConnectedFormGroupDivider />
                            <FormGroupInput
                              disabled={!formProps.values.shouldBlindsIncrease}
                              type="number"
                              hasErrors={hasErrors}
                              id={field.name}
                              sx={lastCardContent}
                              {...field}
                            />
                          </>
                        )}
                      </ConnectedFormGroup>
                      <FormErrorMessage name={'rules.numberOfHandsBetweenBlindIncreases'} />
                    </Col>
                    <Col xs={12} sx={{ mt: 4 }}>
                      <ConnectedFormGroup name={'rules.initialChipsAmount'}>
                        {({ field, hasErrors }) => (
                          <>
                            <FormGroupLabel htmlFor={field.name}>
                              Initial Chips Per Player
                            </FormGroupLabel>
                            <ConnectedFormGroupDivider />
                            <FormGroupInput
                              type="number"
                              hasErrors={hasErrors}
                              id={field.name}
                              sx={lastCardContent}
                              {...field}
                            />
                          </>
                        )}
                      </ConnectedFormGroup>
                      <FormErrorMessage name={'rules.initialChipsAmount'} />
                    </Col>
                    <Col xs={12} sx={{ mt: 4 }}>
                      <ConnectedFormGroup name={'rules.startingBlindAmount'}>
                        {({ field, hasErrors }) => (
                          <>
                            <FormGroupLabel htmlFor={field.name}>
                              Starting Blind Amount
                            </FormGroupLabel>
                            <ConnectedFormGroupDivider />
                            <FormGroupSelect
                              hasErrors={hasErrors}
                              id={field.name}
                              sx={lastCardContent}
                              {...field}
                            >
                              {VALID_BLIND_RAISES.map((num) => (
                                <option value={num} key={num}>
                                  {formatNumberAmount({ amount: num })}
                                </option>
                              ))}
                            </FormGroupSelect>
                          </>
                        )}
                      </ConnectedFormGroup>
                      <FormErrorMessage name={'rules.startingBlindAmount'} />
                    </Col>
                  </Row>
                </Box>
              </Box>
              <GeneralFormErrorMessage sx={{ mt: 3 }} />
              <Txt sx={{ textAlign: 'center', mt: 5 }}>
                <Button
                  size="lg"
                  type="submit"
                  disabled={formProps.isSubmitting || isGeneratingNewGame}
                >
                  <LoadingPlaceholder
                    loadingVariant="light"
                    loading={formProps.isSubmitting || isGeneratingNewGame}
                  >
                    Get Started →
                  </LoadingPlaceholder>
                </Button>
              </Txt>
              {showQuickSearchShortcut && (
                <Txt sx={{ textAlign: 'center', mt: 5 }}>
                  <Button
                    size="lg"
                    onClick={() => {
                      onSubmit(getDemoGameValues(), formProps)
                    }}
                  >
                    Autofill for demo game
                  </Button>
                </Txt>
              )}
            </Form>
          )
        }}
      </Formik>
    </React.Fragment>
  )
}
export const CreateGameForm: React.SFC<{}> = (props) => {
  return (
    <Box sx={{ bg: 'background' }}>
      <SEO title="Create a new poker game" />
      <FormContent />
    </Box>
  )
}
