import React from 'react'

export const useHasMounted = ({ skipRender }: { skipRender?: boolean } = {}) => {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    if (!skipRender) {
      setHasMounted(true)
    }
  }, [])
  return hasMounted
}
